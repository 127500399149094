.btn,
a.btn {
  padding: .5em 1em;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  background: #616161;
}
.btn:hover,
a.btn:hover {
  background: #424242;
}
.btn:active,
a.btn:active {
  background: #212121;
}


/*---- export-buttons ----*/
ul.explore-buttons {
  list-style: none;
  margin: 0;

  > li {
    position: relative;
    list-style: none;
    display: inline-block;
    margin: 0 -4px 0 5px;
    padding: 5px 0 0 0;
    &:first-child{
      margin-left: 0;
    }
    >i{
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      font-weight: normal;
      font-size: 14px;
      color: #fff;
      border-radius: 50%;
      border: 1px solid #DAB46B;
      background: #DAB46B;
      cursor: pointer;
      transition: color .3s, border-color .3s, background-color .3s;
      &:hover{
        background: #e5bf77;
        border-color: #e5bf77;
      }
    }

    > i.explore-favorite-button.is-favorite,
    > i.explore-favorite-button[data-action="delete"]{
      color: #E21C00;
      border-color: #E21C00;
      background: #fff;

      &:hover{
        color: #F50057;
        border-color: #F50057;
        background: #fff;
      }
    }
  }

  > li .muse-btn-wrapper,
  > li .share-btn-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    margin: -25px 0 0 0;

    &:hover{
      display: block;
    }
  }
}


/*---- object item ----*/
.object-item{
  position: relative;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .object-item-media {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 270px;
    position: relative;
    margin: 0 0 15px 0;
    padding: 30px;
    min-height: 270px;
    background: #f5f5f5;
  }
  .object-item-img {
    display: block;
    transition: .3s all;
  }
  a:hover img {
    opacity: .9;
  }
  .object-item-info {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .object-item-title {
    display: block;
    order: 1;
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 1.25rem;
    color: #333;
  }
  a.object-item-title:hover {
    color: #939393;
  }
  .object-item-desc {
    order: 2;
    margin-bottom: 15px;
    color: #5c5b56;
  }
  .object-item-rights {
    order: 3;
    margin-top: auto;
    font-size: .9375em;
    color: #757575;
  }
  .object-item-date {
    order: 4;
    font-size: .9375em;
    color: #999;
  }
  .more {
    order: 10;
  }
  ul.explore-buttons{
    list-style: none;
  }
  .object-item-media ul.explore-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0 12px 8px 0;
    opacity: 0;
    transition: opacity .3s;
  }
  &:hover ul.explore-buttons {
    opacity: 1;
  }

  ul.explore-buttons > li:before {
    box-shadow: 0 0 2px rgba(0,0,0,.5);
  }
}

@import "muse2_obj_list";
@import "muse2_exhibitions";
@import "muse2_curation";