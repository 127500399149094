/* obj list */
.obj-list{
  .view-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  .view-header .items-counter {
    font-size: 1.375rem;
  }
  .view-header .items-counter .count {
    margin: 0 5px;
    color: #3A6ACA;
  }
  .view-header .sort_select {
    margin-top: 20px;
  }

  @include respond-to(m){
    .view-header{
      .sort_select {
        margin-top: 0;
        margin-left: auto;

        + .obj-list-mode-switch {
          margin-left: 30px;
        }
      }
    }
  }
}

.obj-list-mode-switch {
  display: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    flex: 1;
    margin: 0;
    a {
      display: block;
      text-indent: -9999em;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 16px;
      font-size: 0;
      border: 1px solid #dedede;
      background-color: #fff;
      &:hover{}
    }
    a.is-active {
      border: 1px solid #303030;
      background-color: #303030;
    }
    a:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 12px 12px;
    }

    a.grid-mode{
      &::before {
        background-image: url(../images/custom/icon--grid--gray@2x.png);
      }
      &.is-active:before {
        background-image: url(../images/custom/icon--grid--white@2x.png);
      }
    }

    a.list-mode{
      &:before{
        background-image: url(../images/custom/icon--list--gray@2x.png);
      }
      &.is-active:before {
        background-image: url(../images/custom/icon--grid--white@2x.png);
      }
    }
  }

  @include respond-to(m){
    display: flex;
  }
}


/* sidebar */
.sidebar-first{
  
  .block{
    select {
      border-color: #ddd;
    }
    .views-exposed-widget {
      float: none;
      margin-bottom: 20px;
      padding: 0;
    }
    .views-widget-filter-date_end {
      margin-bottom: 0;
    }
    .views-exposed-widget .views-widget {
      display: flex;
    }
    .views-exposed-widget.views-submit-button,
    .views-widget-filter-date_start .views-widget {
      display: flex;
      flex-wrap: wrap;
    }
    .views-exposed-widget.views-submit-button select,
    .views-widget-filter-date_start .views-widget select {
      flex: 0 0 35%;
      border-right: 0;
    }
    .views-widget-filter-date_start .views-widget .form-type-textfield,
    .views-exposed-widget.views-submit-button .form-type-textfield {
      flex: 0 0 65%;
    }
    .form-submit {
      display: block;
      margin-right: 0;
      width: 100%;
      letter-spacing: 1px;
      font-size: 1rem;
      border-radius: 0;
      background: #333;
    }
  }

  .block-facetapi{
    &:not(:last-child) {
      margin-bottom: 32px;
      border-bottom: 1px solid #eee;
    }
    .block-title {
      padding-bottom: 30px;
      line-height: 1;
      font-size: 1.125rem;
      cursor: pointer;
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 3px;
        width: 10px;
        height: 10px;
        background-image: url(../images/custom/icon--plus.jpg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 10px 10px;
      }
    }

    &.is-open{
      .block-title:before {
        background-image: url(../images/custom/icon--minus.jpg);
        background-size: 10px 2px;
      }
    }

    .block-content {
      padding-bottom: 28px;
    }

    .facetapi-limit-link {
      text-decoration: underline;
      font-size: .875rem;
    }

    .md-checkbox .elem-label {
      font-size: .9375rem;
      &:before {
        border-color: #eee;
      }
      &:after {
        border-color: #333;
      }
    }

    .md-checkbox input[type="checkbox"]:checked + .elem-label:after {
      background-color: #333;
    }
  }

  ul.facetapi-facetapi-checkbox-links li {
    margin-bottom: 15px;
  }

}