/* exhibition main */
.exhibition-main{
  > .view-inner > .view-content {
    .views-row {
      padding-top: 5px;
    }
    .views-row-inner {
      position: relative;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
    }

    .object-item{
      .object-item-media {
        display: block;
        margin: 0;
        padding: 0;
        min-height: 0;
        background: none;
      }
    }

    .object-item-media{
      a {
        display: block;
      }
      a img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .featureds-note {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      margin: -5px 0 0 25px;
      padding: 5px 15px;
      line-height: 1.2;
      font-size: .9375rem;
      color: #fff;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #e0382d;
    }

    .object-item .object-item-info{
      padding: 25px 20px;
      color: #5c5b56;

      > div {
        word-break: break-all;
      }
    }

    .object-item .object-item-title {
      margin: 0;
    }
    .object-item .object-item-rights {
      order: 2;
      color: #5c5b56;
    }
    .object-item .object-item-desc {
      margin-bottom: 0;
    }
    .views-row .more {
      a {
        display: inline-block;
        padding: 10px 20px;
        color: #fff;
        text-align: center;
        background: #333;
        border-radius: 2px;
        &:hover {
          border-color: #555;
          background: #555;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  
  .attachment-after .exhibition-list,
  .exhibition-list.with-bg {
    padding-top: 60px;
    padding-bottom: 60px;
    background: rgba(224,180,97,.3);
  }
  .attachment-after .exhibition-list {
    margin-top: -130px;
    padding-top: 180px;
    padding-bottom: 60px;
  }
}

/* exhibition list */
.exhibition-list{
  .slick-arrow {
    width: 55px;
    height: 55px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    &:before {
      display: none;
    }
  }
  
  .slick-initialized ~ .slick__arrow.element-hidden {
    display: block;
  }
  .slick-prev {
    left: -15px;
    background-image: url(../images/custom/icon--slideshow-prev.png);
  }
  .slick-next {
    right: -15px;
    background-image: url(../images/custom/icon--slideshow-next.png);
  }
  .slick-dots {
    bottom: -55px;

    li {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: 0 3px;
    }
    li button {
      position: relative;
      padding: 0;
      /*
      text-indent: -9999em;
      overflow: hidden;
      */
      overflow: visible;
      width: 10px;
      height: 10px;
      background: rgba(51, 51, 51, .3);
      border-radius: 15px;
      margin: 0;
      opacity: 1;
      transition: background-color .1s;
    }

    li.slick-active button,
    li button:hover {
      background: #DAB46D;
      box-shadow: 0 0 3px rgba(0,0,0,0);
      opacity: 1;
    }

    li button:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 14px;
      height: 14px;
      border: 1px solid transparent;
      border-radius: 50%;
      background: transparent;
      transition: .3s all;
    }
    li.slick-active button:after,
    li button:hover:after {
      border-color: #DAB46D;
    }
  }

  .object-item {
    overflow: hidden;
    border-radius: 10px;

    .object-item-media {
      display: block;
      margin: 0;
      padding: 0;
      min-height: 0;
      background: none;
    }

    .object-item-info {
      padding: 25px 20px;
    }

    .object-item-title {
      margin: 0;
    }
  }
  .object-item-media a {
    display: block;
  }
  .object-item-media a img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .object-item-img-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 10px;
    overflow: hidden;
  }
  .object-item-img-main {
    flex: 0 0 100%;
    margin-bottom: 2px;
  }
  .object-item-img-thumb {
    flex: 0 0 33%;
  }
  .featureds-note {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    margin: -5px 0 0 25px;
    padding: 5px 15px;
    line-height: 1.2;
    font-size: .9375rem;
    color: #fff;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #e0382d;
  }

  .more,
  .view-footer .more,
  .read-more,
  .view-footer .read-more {
    margin-top: 50px;
  }
}

/* exhibition list (col 4) */
.exhibition-list-col-4{
  .featureds-note,
  .explore-buttons {
    display: none;
  }
  .object-item-info {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 30px 18px;
    color: #fff;
    background: rgba(0,0,0,.5);
  }
  .object-item-title {
    letter-spacing: 0;
    font-size: 1.125rem;
    color: #fff;
  }
}

/* exhibition list (float) */
.exhibition-list-float{
  .views-row {
    margin-bottom: 30px;
  }
  .views-row-inner {
    position: relative;
    border-radius: 10px;
    border: 1px solid #dedede;
    background: #fff;
  }
  .object-item-media {
    display: block;
    margin: 0;
    padding: 0;
    min-height: 0;
    background: none;
    a {
      display: block;
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }

  .featureds-note {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    margin: -5px 0 0 25px;
    padding: 5px 15px;
    line-height: 1.2;
    font-size: .9375rem;
    color: #fff;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #e0382d;
  }

  .object-item-info {
    padding: 20px;
    color: #5c5b56;
  }
  .object-item-info > div {
    word-break: break-all;
  }
  .object-item-title {
    margin-bottom: 0;
  }
  .object-item-desc {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .object-item-rights {
    order: 2;
    color: #5c5b56;
  }
  .views-row{
    .more {
      display: none;
    }
    .more a {
      display: block;
      padding: 10px 20px;
      width: 160px;
      color: #fff;
      text-align: center;
      background: #333;
      border-radius: 2px;
      &:hover {
        border-color: #555;
        background: #555;
      }
      &:after{
        display: none;
      }
    }
  }
}