:root {
  --theme-color-primary:#3a6aca;
}

header.header {
  background-color: #fff;
}
header.header .logo img {
  max-height: 60px;
  width: auto;
}

.region-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.mobile-header-inner {
  height: 60px;
}

.not-front .main-inner {
  padding-top: 0;
}

.crumbs-wrapper {
  background-color: #f4f2ef;
  padding: 37px 0 27px;
}
.crumbs-wrapper, .crumbs-wrapper a {
  color: #9f9f9f;
}

.page-title {
  font-size: 36px;
  font-weight: 400;
}

.footer {
  background-color: #333;
}
.footer .footer-info {
  display: flex;
  justify-content: space-between;
  color: #959595;
  margin-bottom: 1em;
}
.footer .footer-contact {
  text-align: right;
  line-height: 26px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
.footer .footer-support-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer .support-item-title {
  width: 100px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1px;
}
.footer .support-item-content img {
  height: 34px;
}
.footer .footer-support-provide-content img {
  height: 48px;
}
.footer .block-footer-menu {
  padding-top: 1em;
}
.footer .block-footer-menu a {
  color: #959595;
}
.footer .block-footer-menu .menu > li {
  margin-right: 2em;
}

.btn,
a.btn {
  padding: 0.5em 1em;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  background: #616161;
}

.btn:hover,
a.btn:hover {
  background: #424242;
}

.btn:active,
a.btn:active {
  background: #212121;
}

/*---- export-buttons ----*/
ul.explore-buttons {
  list-style: none;
  margin: 0;
}
ul.explore-buttons > li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin: 0 -4px 0 5px;
  padding: 5px 0 0 0;
}
ul.explore-buttons > li:first-child {
  margin-left: 0;
}
ul.explore-buttons > li > i {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-weight: normal;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #DAB46B;
  background: #DAB46B;
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
}
ul.explore-buttons > li > i:hover {
  background: #e5bf77;
  border-color: #e5bf77;
}
ul.explore-buttons > li > i.explore-favorite-button.is-favorite,
ul.explore-buttons > li > i.explore-favorite-button[data-action=delete] {
  color: #E21C00;
  border-color: #E21C00;
  background: #fff;
}
ul.explore-buttons > li > i.explore-favorite-button.is-favorite:hover,
ul.explore-buttons > li > i.explore-favorite-button[data-action=delete]:hover {
  color: #F50057;
  border-color: #F50057;
  background: #fff;
}
ul.explore-buttons > li .muse-btn-wrapper,
ul.explore-buttons > li .share-btn-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin: -25px 0 0 0;
}
ul.explore-buttons > li .muse-btn-wrapper:hover,
ul.explore-buttons > li .share-btn-wrapper:hover {
  display: block;
}

/*---- object item ----*/
.object-item {
  position: relative;
}
.object-item:after {
  content: "";
  display: block;
  clear: both;
}
.object-item .object-item-media {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 270px;
  position: relative;
  margin: 0 0 15px 0;
  padding: 30px;
  min-height: 270px;
  background: #f5f5f5;
}
.object-item .object-item-img {
  display: block;
  transition: 0.3s all;
}
.object-item a:hover img {
  opacity: 0.9;
}
.object-item .object-item-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.object-item .object-item-title {
  display: block;
  order: 1;
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 1.25rem;
  color: #333;
}
.object-item a.object-item-title:hover {
  color: #939393;
}
.object-item .object-item-desc {
  order: 2;
  margin-bottom: 15px;
  color: #5c5b56;
}
.object-item .object-item-rights {
  order: 3;
  margin-top: auto;
  font-size: 0.9375em;
  color: #757575;
}
.object-item .object-item-date {
  order: 4;
  font-size: 0.9375em;
  color: #999;
}
.object-item .more {
  order: 10;
}
.object-item ul.explore-buttons {
  list-style: none;
}
.object-item .object-item-media ul.explore-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 12px 8px 0;
  opacity: 0;
  transition: opacity 0.3s;
}
.object-item:hover ul.explore-buttons {
  opacity: 1;
}
.object-item ul.explore-buttons > li:before {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

/* obj list */
.obj-list .view-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.obj-list .view-header .items-counter {
  font-size: 1.375rem;
}
.obj-list .view-header .items-counter .count {
  margin: 0 5px;
  color: #3A6ACA;
}
.obj-list .view-header .sort_select {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .obj-list .view-header .sort_select {
    margin-top: 0;
    margin-left: auto;
  }
  .obj-list .view-header .sort_select + .obj-list-mode-switch {
    margin-left: 30px;
  }
}

.obj-list-mode-switch {
  display: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.obj-list-mode-switch li {
  flex: 1;
  margin: 0;
}
.obj-list-mode-switch li a {
  display: block;
  text-indent: -9999em;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 16px;
  font-size: 0;
  border: 1px solid #dedede;
  background-color: #fff;
}
.obj-list-mode-switch li a.is-active {
  border: 1px solid #303030;
  background-color: #303030;
}
.obj-list-mode-switch li a:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 12px 12px;
}
.obj-list-mode-switch li a.grid-mode::before {
  background-image: url(../images/custom/icon--grid--gray@2x.png);
}
.obj-list-mode-switch li a.grid-mode.is-active:before {
  background-image: url(../images/custom/icon--grid--white@2x.png);
}
.obj-list-mode-switch li a.list-mode:before {
  background-image: url(../images/custom/icon--list--gray@2x.png);
}
.obj-list-mode-switch li a.list-mode.is-active:before {
  background-image: url(../images/custom/icon--grid--white@2x.png);
}
@media (min-width: 768px) {
  .obj-list-mode-switch {
    display: flex;
  }
}

/* sidebar */
.sidebar-first .block select {
  border-color: #ddd;
}
.sidebar-first .block .views-exposed-widget {
  float: none;
  margin-bottom: 20px;
  padding: 0;
}
.sidebar-first .block .views-widget-filter-date_end {
  margin-bottom: 0;
}
.sidebar-first .block .views-exposed-widget .views-widget {
  display: flex;
}
.sidebar-first .block .views-exposed-widget.views-submit-button,
.sidebar-first .block .views-widget-filter-date_start .views-widget {
  display: flex;
  flex-wrap: wrap;
}
.sidebar-first .block .views-exposed-widget.views-submit-button select,
.sidebar-first .block .views-widget-filter-date_start .views-widget select {
  flex: 0 0 35%;
  border-right: 0;
}
.sidebar-first .block .views-widget-filter-date_start .views-widget .form-type-textfield,
.sidebar-first .block .views-exposed-widget.views-submit-button .form-type-textfield {
  flex: 0 0 65%;
}
.sidebar-first .block .form-submit {
  display: block;
  margin-right: 0;
  width: 100%;
  letter-spacing: 1px;
  font-size: 1rem;
  border-radius: 0;
  background: #333;
}
.sidebar-first .block-facetapi:not(:last-child) {
  margin-bottom: 32px;
  border-bottom: 1px solid #eee;
}
.sidebar-first .block-facetapi .block-title {
  padding-bottom: 30px;
  line-height: 1;
  font-size: 1.125rem;
  cursor: pointer;
}
.sidebar-first .block-facetapi .block-title:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 3px;
  width: 10px;
  height: 10px;
  background-image: url(../images/custom/icon--plus.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 10px 10px;
}
.sidebar-first .block-facetapi.is-open .block-title:before {
  background-image: url(../images/custom/icon--minus.jpg);
  background-size: 10px 2px;
}
.sidebar-first .block-facetapi .block-content {
  padding-bottom: 28px;
}
.sidebar-first .block-facetapi .facetapi-limit-link {
  text-decoration: underline;
  font-size: 0.875rem;
}
.sidebar-first .block-facetapi .md-checkbox .elem-label {
  font-size: 0.9375rem;
}
.sidebar-first .block-facetapi .md-checkbox .elem-label:before {
  border-color: #eee;
}
.sidebar-first .block-facetapi .md-checkbox .elem-label:after {
  border-color: #333;
}
.sidebar-first .block-facetapi .md-checkbox input[type=checkbox]:checked + .elem-label:after {
  background-color: #333;
}
.sidebar-first ul.facetapi-facetapi-checkbox-links li {
  margin-bottom: 15px;
}

/* exhibition main */
.exhibition-main > .view-inner > .view-content .views-row {
  padding-top: 5px;
}
.exhibition-main > .view-inner > .view-content .views-row-inner {
  position: relative;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fff;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-media {
  display: block;
  margin: 0;
  padding: 0;
  min-height: 0;
  background: none;
}
.exhibition-main > .view-inner > .view-content .object-item-media a {
  display: block;
}
.exhibition-main > .view-inner > .view-content .object-item-media a img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.exhibition-main > .view-inner > .view-content .featureds-note {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin: -5px 0 0 25px;
  padding: 5px 15px;
  line-height: 1.2;
  font-size: 0.9375rem;
  color: #fff;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #e0382d;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-info {
  padding: 25px 20px;
  color: #5c5b56;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-info > div {
  word-break: break-all;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-title {
  margin: 0;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-rights {
  order: 2;
  color: #5c5b56;
}
.exhibition-main > .view-inner > .view-content .object-item .object-item-desc {
  margin-bottom: 0;
}
.exhibition-main > .view-inner > .view-content .views-row .more a {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  background: #333;
  border-radius: 2px;
}
.exhibition-main > .view-inner > .view-content .views-row .more a:hover {
  border-color: #555;
  background: #555;
}
.exhibition-main > .view-inner > .view-content .views-row .more a:after {
  display: none;
}
.exhibition-main .attachment-after .exhibition-list,
.exhibition-main .exhibition-list.with-bg {
  padding-top: 60px;
  padding-bottom: 60px;
  background: rgba(224, 180, 97, 0.3);
}
.exhibition-main .attachment-after .exhibition-list {
  margin-top: -130px;
  padding-top: 180px;
  padding-bottom: 60px;
}

/* exhibition list */
.exhibition-list .slick-arrow {
  width: 55px;
  height: 55px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.exhibition-list .slick-arrow:before {
  display: none;
}
.exhibition-list .slick-initialized ~ .slick__arrow.element-hidden {
  display: block;
}
.exhibition-list .slick-prev {
  left: -15px;
  background-image: url(../images/custom/icon--slideshow-prev.png);
}
.exhibition-list .slick-next {
  right: -15px;
  background-image: url(../images/custom/icon--slideshow-next.png);
}
.exhibition-list .slick-dots {
  bottom: -55px;
}
.exhibition-list .slick-dots li {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 3px;
}
.exhibition-list .slick-dots li button {
  position: relative;
  padding: 0;
  /*
  text-indent: -9999em;
  overflow: hidden;
  */
  overflow: visible;
  width: 10px;
  height: 10px;
  background: rgba(51, 51, 51, 0.3);
  border-radius: 15px;
  margin: 0;
  opacity: 1;
  transition: background-color 0.1s;
}
.exhibition-list .slick-dots li.slick-active button,
.exhibition-list .slick-dots li button:hover {
  background: #DAB46D;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0);
  opacity: 1;
}
.exhibition-list .slick-dots li button:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 14px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 50%;
  background: transparent;
  transition: 0.3s all;
}
.exhibition-list .slick-dots li.slick-active button:after,
.exhibition-list .slick-dots li button:hover:after {
  border-color: #DAB46D;
}
.exhibition-list .object-item {
  overflow: hidden;
  border-radius: 10px;
}
.exhibition-list .object-item .object-item-media {
  display: block;
  margin: 0;
  padding: 0;
  min-height: 0;
  background: none;
}
.exhibition-list .object-item .object-item-info {
  padding: 25px 20px;
}
.exhibition-list .object-item .object-item-title {
  margin: 0;
}
.exhibition-list .object-item-media a {
  display: block;
}
.exhibition-list .object-item-media a img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.exhibition-list .object-item-img-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 10px;
  overflow: hidden;
}
.exhibition-list .object-item-img-main {
  flex: 0 0 100%;
  margin-bottom: 2px;
}
.exhibition-list .object-item-img-thumb {
  flex: 0 0 33%;
}
.exhibition-list .featureds-note {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin: -5px 0 0 25px;
  padding: 5px 15px;
  line-height: 1.2;
  font-size: 0.9375rem;
  color: #fff;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #e0382d;
}
.exhibition-list .more,
.exhibition-list .view-footer .more,
.exhibition-list .read-more,
.exhibition-list .view-footer .read-more {
  margin-top: 50px;
}

/* exhibition list (col 4) */
.exhibition-list-col-4 .featureds-note,
.exhibition-list-col-4 .explore-buttons {
  display: none;
}
.exhibition-list-col-4 .object-item-info {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 18px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
.exhibition-list-col-4 .object-item-title {
  letter-spacing: 0;
  font-size: 1.125rem;
  color: #fff;
}

/* exhibition list (float) */
.exhibition-list-float .views-row {
  margin-bottom: 30px;
}
.exhibition-list-float .views-row-inner {
  position: relative;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fff;
}
.exhibition-list-float .object-item-media {
  display: block;
  margin: 0;
  padding: 0;
  min-height: 0;
  background: none;
}
.exhibition-list-float .object-item-media a {
  display: block;
}
.exhibition-list-float .object-item-media a img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.exhibition-list-float .featureds-note {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin: -5px 0 0 25px;
  padding: 5px 15px;
  line-height: 1.2;
  font-size: 0.9375rem;
  color: #fff;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #e0382d;
}
.exhibition-list-float .object-item-info {
  padding: 20px;
  color: #5c5b56;
}
.exhibition-list-float .object-item-info > div {
  word-break: break-all;
}
.exhibition-list-float .object-item-title {
  margin-bottom: 0;
}
.exhibition-list-float .object-item-desc {
  margin-top: 10px;
  margin-bottom: 0;
}
.exhibition-list-float .object-item-rights {
  order: 2;
  color: #5c5b56;
}
.exhibition-list-float .views-row .more {
  display: none;
}
.exhibition-list-float .views-row .more a {
  display: block;
  padding: 10px 20px;
  width: 160px;
  color: #fff;
  text-align: center;
  background: #333;
  border-radius: 2px;
}
.exhibition-list-float .views-row .more a:hover {
  border-color: #555;
  background: #555;
}
.exhibition-list-float .views-row .more a:after {
  display: none;
}

/* curation main */
.curation-main > .view-inner > .view-content .object-item {
  overflow: hidden;
  border-radius: 5px;
}
.curation-main > .view-inner > .view-content .object-item-media {
  display: block;
  position: relative;
  flex: 0 0 73.53%;
  margin: 0;
  padding: 0;
  min-height: 0;
  background: none;
}
.curation-main > .view-inner > .view-content .object-item-img-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.curation-main > .view-inner > .view-content .object-item-img-wrapper .object-item-img-main {
  flex: 0 0 85%;
}
.curation-main > .view-inner > .view-content .object-item-img-wrapper .object-item-img-thumbs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 14.923%;
}
.curation-main .object-item-info {
  display: block;
  padding: 20px 5px;
}
.curation-main .object-item-info .featureds-note {
  display: inline-flex;
  margin-bottom: 15px;
  padding: 5px 7px;
  line-height: 1;
  font-size: 0.9375rem;
  color: #fff;
  border-radius: 2px;
  background: #e0b461;
}
.curation-main .object-item-info h2 {
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 1.5rem;
}
.curation-main .object-item-info .object-item-title {
  font-size: 1.5rem;
}
.curation-main .object-item-info .follow-main-desc {
  height: 6.52rem;
  overflow: hidden;
  text-align: justify;
  line-height: 1.63;
  letter-spacing: 1px;
  font-size: 1rem;
  color: #5c5b56;
}
.curation-main .object-item-info .follow-main-more {
  display: flex;
  align-items: center;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.curation-main .object-item-info .follow-main-more:before {
  content: "";
  display: block;
  margin-right: 10px;
  width: 30px;
  height: 1px;
  background: #5c5b56;
}
.curation-main .object-item-info .follow-main-more a {
  color: #5c5b56;
}
.curation-main .attachment-after {
  margin-top: 12px;
}

/* ---  Component in header --- */
@media (min-width: 1200px) {
  .header #muse_search_block {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}
.header #muse_search_block .form-item {
  margin: 0;
}
.header #muse_search_block .form-text {
  opacity: 0;
  padding: 0;
  width: 0;
  color: #fff;
  border: 0;
  border-bottom: 1px solid transparent;
  background: none;
  box-shadow: none;
  transition: 0.3s all;
}
.header #muse_search_block .form-submit {
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999em;
  overflow: hidden;
  background-size: 16px 16px;
}

#block-superfish-1 {
  margin-right: 30px;
}

.for-mobile {
  display: inherit;
}

.for-desktop {
  display: none;
}

@media (min-width: 1200px) {
  .for-mobile {
    display: none;
  }
  .for-desktop {
    display: inherit;
  }
}