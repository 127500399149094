/* curation main */
.curation-main{
  > .view-inner > .view-content{
    .object-item {
      overflow: hidden;
      border-radius: 5px;
    }
    .object-item-media {
      display: block;
      position: relative;
      flex: 0 0 73.53%;
      margin: 0;
      padding: 0;
      min-height: 0;
      background: none;
    }

    .object-item-img-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .object-item-img-main {
        flex: 0 0 85%;
      }

      .object-item-img-thumbs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 14.923%;
      }
    }
  }

  .object-item-info {
    display: block;
    padding: 20px 5px;

    .featureds-note {
      display: inline-flex;
      margin-bottom: 15px;
      padding: 5px 7px;
      line-height: 1;
      font-size: .9375rem;
      color: #fff;
      border-radius: 2px;
      background: #e0b461;
    }

    h2 {
      margin-bottom: 15px;
      font-weight: normal;
      font-size: 1.5rem;
    }

    .object-item-title {
      font-size: 1.5rem;
    }

    .follow-main-desc {
      height: calc(1rem * 1.63 * 4);
      overflow: hidden;
      text-align: justify;
      line-height: 1.63;
      letter-spacing: 1px;
      font-size: 1rem;
      color: #5c5b56;
    }

    .follow-main-more {
      display: flex;
      align-items: center;
      margin-top: 15px;
      text-transform: uppercase;
      font-size: .75rem;

      &:before {
        content: "";
        display: block;
        margin-right: 10px;
        width: 30px;
        height: 1px;
        background: #5c5b56;
      }
    }

    .follow-main-more a {
      color: #5c5b56;
    }
  }

  .attachment-after {
    margin-top: 12px;
  }
}