@import "vars";
@import "mixins";

:root{
  --theme-color-primary:#3a6aca;
}

header.header{
  background-color: #fff;
  .logo img{
    max-height: 60px;
    width: auto;
  }
}

.region-header{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.mobile-header-inner{
  height: 60px;
}

.not-front .main-inner{
  padding-top: 0;
}

.crumbs-wrapper{
  background-color: #f4f2ef;
  padding: 37px 0 27px;
  //margin-bottom: 2rem;
  &,a{
    color: #9f9f9f;
  }
}

.main-top{
}


.page-title{
  font-size: 36px;
  font-weight: 400;
}

.footer{
  background-color: #333;

  .footer-info{
    display: flex;
    justify-content: space-between;
    color: #959595;
    margin-bottom: 1em;
  }
  .footer-support{
  }
  .footer-contact{
    text-align: right;
    line-height: 26px;
    @include font-style(14px,26px,1px);
  }

  .footer-support-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .support-item-title{
    width: 100px;
    @include font-style(14px,21px,1px);

  }
  .support-item-content img{
    height: 34px;
  }
  .footer-support-provide-content{
    img{height: 48px;}
  }

  .block-footer-menu{
    //border-top: 1px solid #959595;
    padding-top: 1em;
    a{
      color: #959595;
    }
    .menu>li{
      margin-right: 2em;
    }
  }
}

@import "muse2_components";


/* ---  Component in header --- */
.header{
  #muse_search_block{
    @include respond-to(xl){
      padding: 10px;
      width: 60px;
      height: 60px;
    }
    .form-item{
      margin: 0;
    }
    .form-text{
      opacity: 0;
      padding: 0;
      width: 0;
      color: #fff;
      border: 0;
      border-bottom: 1px solid transparent;
      background: none;
      box-shadow: none;
      transition: .3s all;
    }

    .form-submit{
      margin: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      text-indent: -9999em;
      overflow: hidden;
      //background: url(../images/icon--search.svg) no-repeat 50% 50%;
      background-size: 16px 16px;
    }
  }
}
#block-superfish-1{margin-right: 30px;}

.for-mobile{
  display: inherit;
}
.for-desktop{
  display: none;
}
@media (min-width: $window-min-width-xl) {
  .for-mobile{
    display: none;
  }
  .for-desktop{
    display: inherit;
  }
}